import React from "react"
import { Flex, Box, Text, Link, Stack } from "@chakra-ui/react"

const Footer = props => {
  return (
    <Flex
      as="footer"
      bg={"brand.100"}
      borderTop="2px solid black"
      w="100%"
      align="top"
      justify="space-between"
      px={[6, 6, 12]}
      py={[4]}
    >
      <Box>
        <Text as="span" fontSize={["sm", "md"]}>
          © Chromecki Jesche-Chromecki
        </Text>
      </Box>
      <Box>
        <Stack isInline spacing={10} fontSize={["sm", "md"]}>
          <Link href="/datenschutz">Datenschutz</Link>
          <Link href="/impressum">Impressum</Link>
        </Stack>
      </Box>
    </Flex>
  )
}

export default Footer
