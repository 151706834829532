import React from "react"

export const Context = React.createContext({
  value: false,
  callback: bool => {},
})

export const CollapseContext = React.createContext({
  collapsed: false,
  callback: bool => {},
})
