import React from "react"
import PropTypes from "prop-types"
import { motion, AnimatePresence, LayoutGroup } from "framer-motion"
import Nav from "./nav"
import Footer from "./footer"
import { Box, Link, theme } from "@chakra-ui/react"
import "./layout.css"
import { Context } from "./bgContext"
import CookieConsent from "react-cookie-consent"
import { BreakpointProvider } from "./breakpoint"

const queries = {
  // xs: `(min-width: ${theme.breakpoints.sm})`,
  sm: `(min-width: ${theme.breakpoints.sm})`,
  md: `(min-width: ${theme.breakpoints.md})`,
  lg: `(min-width: ${theme.breakpoints.lg})`,
  xl: `(min-width: ${theme.breakpoints.xl})`,
  // or: `(max-width: ${theme.breakpoints.sm})`, // we can check orientation also
}

const MotionBox = motion(Box)

const duration = 0.3

const variants = {
  initial: {
    opacity: 0,
    // y: 30,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      duration: duration,
      delay: duration,
      when: "beforeChildren",
      ease: "easeInOut",
    },
  },
  exit: {
    opacity: 0,
    // y: 30,
    transition: { duration: duration, ease: "easeInOut" },
  },
}

const Layout = ({ children, ...props }) => {
  const [value, callback] = React.useState(false)

  React.useLayoutEffect(() => {
    callback(value)
  }, [value])

  return (
    <Context.Provider value={{ value, callback }}>
      <BreakpointProvider queries={queries}>
        <LayoutGroup>
          <Nav bg={value} {...props} />
        </LayoutGroup>
        <AnimatePresence>
          <MotionBox
            id="top"
            as="main"
            pt={[32, 40]}
            key={props.location.pathname}
            variants={variants}
            initial="initial"
            animate="enter"
            exit="exit"
          >
            {children}
            <Footer />
          </MotionBox>
        </AnimatePresence>

        <CookieConsent
          location="bottom"
          buttonText="Akzeptieren"
          declineButtonText="Ablehnen"
          enableDeclineButton
          cookieName="gatsby-gdpr-google-analytics"
          style={{ fontSize: 12, backgroundColor: "black" }}
          declineButtonStyle={{
            backgroundColor: "transparent",
            border: "1px solid white",
          }}
          buttonStyle={{ backgroundColor: "white" }}
          debug={false}
        >
          Wir verwenden Cookies, um unsere Webseite für Sie möglichst
          benutzerfreundlich zu gestalten bzw. zu analysieren. Wenn Sie
          fortfahren, nehmen wir an, dass Sie mit der Verwendung von Cookies
          einverstanden sind. Weitere Informationen finden Sie unter{" "}
          <Link style={{ textDecoration: "underline" }} href="/datenschutz">
            Datenschutz
          </Link>
        </CookieConsent>
      </BreakpointProvider>
    </Context.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
