import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { motion } from "framer-motion"
import Logo from "./logo"
import { Flex, Box } from "@chakra-ui/react"
import { CloseIcon } from '@chakra-ui/icons'
import { useBreakpoint } from "../components/breakpoint.js"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useWindowWidth } from "@react-hook/window-size"
import useDidMount from "@rooks/use-did-mount"

const MotionFlex = motion(Flex)
const MotionBox = motion(Box)
const MotionLink = motion(Link)

// Navigation element to navigate between pages
const Nav = props => {
  const data = useStaticQuery(graphql`
  query {
    allPrismicNavigation {
      nodes {
        data {
          navigation_links {
            label {
              text
            }
            link {
              text
            }
          }
        }
      }
    }
  }
`)

// console.log(props)

  const [navHeight, setNavHeight] = React.useState(70)
  const breakpoints = useBreakpoint()
  const winWidth = useWindowWidth()

  const [mobLeftOffset, setMobLeftOffset] = React.useState(0)
  const [currentLeftOffset, setCurrentLeftOffset] = React.useState(null)
  const [clickAllowed, setClickAllowed] = React.useState(true)
  const [burgerActive, setBurgerActive] = React.useState(true)
  // const [isUserAction, setIsUserAction] = React.useState(false)
  // const [currentLogoOffset, setCurrentLogoOffset] = React.useState(0)
  const [currentPath, setCurrentPath] = React.useState(props.path)
  const [ready, setReady] = React.useState(false)

  const [mobNavVisible, setMobNavVisible] = React.useState(null)

  const activeItemRef = React.useRef()
  const burgerRef = React.useRef()

  function onStart() {
    // console.log("animation start")
    setBurgerActive(false)
    setClickAllowed(false)
  }

  function onComplete() {
    // console.log("animation complete")
    setBurgerActive(true)
    setClickAllowed(true)
    // console.log("allow clicks")

    // if(mobNavVisible && clickCount)
  }

  const calcOffset = () => {
    if (activeItemRef.current) {
      // console.log("calc")
      const val =
        burgerRef.current.getBoundingClientRect().left -
        (activeItemRef.current.getBoundingClientRect().left +
          activeItemRef.current.getBoundingClientRect().width)

      setCurrentLeftOffset(val)
      return val
    }
  }

  React.useEffect(() => {
    setNavHeight(breakpoints["md"] ? 88 : 70)
  }, [breakpoints])

  // React.useLayoutEffect(() => {
  //   setMobLeftOffset(calcOffset())
  //   setCurrentLeftOffset(calcOffset())
  //   setCurrentPath(props.path)

  //   setReady(true)
  // }, [])

  // TODO: FIX RESIZE BEHAVIOR
  // React.useEffect(() => {
  //   setReady(false)
  //   setMobLeftOffset(calcOffset())
  //   setCurrentLeftOffset(calcOffset())
  //   setCurrentPath(props.path)

  //   setReady(true)
  // }, [winWidth])

  React.useEffect(() => {
    // console.log("path change")
    props.path !== currentPath && setCurrentPath(props.path)
    setMobLeftOffset(calcOffset())
    // setCurrentLeftOffset(calcOffset())
    // console.log("disable clicks")
    setClickAllowed(false)
    if (ready) {
      setTimeout(() => {
        mobTapHandler(false)
      }, 500)
    }

    // setIsUserAction(false)
  }, [props.path])

  useDidMount(function () {
    // console.log("mount")
    setMobLeftOffset(calcOffset())
    setCurrentLeftOffset(calcOffset())
    setCurrentPath(props.path)

    setReady(true)
  })

  const mobTapHandler = (visible, source = "") => {
    console.log("4. here", source)
    console.log("visible", visible)

    !visible
      ? setMobLeftOffset(
          props.path !== currentPath && activeItemRef.current
            ? calcOffset()
            : currentLeftOffset
        )
      : setMobLeftOffset(0)

    setMobNavVisible(visible)
  }

  return (
    <>
      <MotionFlex
        pr={[6, 6, 12]}
        as="nav"
        pos="fixed"
        top={0}
        w="100%"
        zIndex={100}
        background={
          props.bg
            ? "rgba(232,235,237,1)"
            : "linear-gradient(to bottom, rgba(232,235,237,1) 0%,rgba(232,235,237,0.75) 40%,rgba(232,235,237,0) 95%,rgba(232,235,237,0) 100%)"
        }
        align="center"
        justify="space-between"
        h={`${navHeight}px`}
        initial={{ opacity: 0 }}
        animate={{
          height: navHeight,
          opacity: 1,
        }}
        transition={{
          ease: "easeInOut",
          duration: 0.2,
        }}
      >
        <MotionBox
          pl={[6, 6, 12]}
          pos="relative"
          zIndex={mobNavVisible ? 0 : 100}
          initial={{ left: 0, opacity: 0 }}
          animate={{
            left: !mobNavVisible ? 0 : -currentLeftOffset,
            opacity: !mobNavVisible ? 1 : 0,
          }}
          transition={{
            ease: "easeInOut",
            duration: 0.4,
          }}
          onClick={() =>
            !breakpoints["md"] &&
            props.path !== "/" &&
            mobTapHandler(!mobNavVisible, "logo")
          }
          style={{
            pointerEvents:
              breakpoints["md"] || (!mobNavVisible && clickAllowed)
                ? "auto"
                : "none",
          }}
        >
          <AnchorLink
            to={`/#top`}
            title={
              data.allPrismicNavigation.nodes[0].data.navigation_links[0].label.text
            }
            stripHash
          >
            <Logo version="small" width={["106px", "106px", "120px"]} />
          </AnchorLink>
        </MotionBox>

        <Box>
          <MotionBox display={["none", "none", "block"]}>
            
              <motion.ul className="nav" layout>
                {data.allPrismicNavigation.nodes[0].data.navigation_links.map(
                  (page, i) => {
                    const isCurrentPage = props.path === page.link.text

                    return (
                      <motion.li
                        whileTap={{ scale: 0.95 }}
                        style={{ marginLeft: 40 }}
                        key={i}
                        className="nav-link"
                      >
                        <MotionLink to={page.link.text} key={page.label.text}>
                          <motion.div
                            style={{ padding: "8px 16px 7px" }}
                            animate={{
                              color: isCurrentPage
                                ? "rgb(255,255,255)"
                                : "rgb(0,0,0)",
                            }}
                            transition={{
                              ease: "easeInOut",
                              duration: 0.4,
                            }}
                          >
                            {page.label.text}
                          </motion.div>
                          {isCurrentPage && (
                            <motion.div
                              layoutId="nav-highlight"
                              className="nav-highlight"
                              style={{
                                backgroundColor: "black",
                                borderRadius: 20,
                                zIndex: -1,
                              }}
                            />
                          )}
                        </MotionLink>
                      </motion.li>
                    )
                  }
                )}
              </motion.ul>
            
          </MotionBox>

          <MotionBox
            ref={burgerRef}
            display={["flex", "flex", "none"]}
            pos="absolute"
            top="17px"
            right={6}
            width="36px"
            height="36px"
            borderRadius="50%"
            backgroundColor="black"
            color="white"
            alignItems="center"
            justifyContent="center"
            onClick={() =>
              mobTapHandler(!mobNavVisible, "burger")
            }
            // style={{
            //   pointerEvents: burgerActive && clickAllowed ? "auto" : "none",
            // }}
            zIndex={200}
          >
            {!mobNavVisible ? (
              <svg
                width="16"
                height="8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.854.603H.14V1.86h15.713V.603zm.004 6H.145V7.86h15.713V6.603z"
                  fill="currentColor"
                />
              </svg>
            ) : (
              <CloseIcon boxSize="12px" />
            )}
          </MotionBox>

          <MotionBox
            display={["block", "block", "none"]}
            zIndex={90}
            top={"50%"}
            style={{
              transform: "translateY(-50%)",
            }}
            width={"100%"}
            pos="absolute"
            className="mobNav"
            left={mobLeftOffset}
            animate={{
              left: mobNavVisible && ready ? 0 : mobLeftOffset,
            }}
            onAnimationComplete={onComplete}
            onAnimationStart={onStart}
            transition={{
              ease: "easeInOut",
              duration: ready && mobNavVisible !== null ? 0.4 : 0,
            }}
          >
            <ul className="nav">
              {data.allPrismicNavigation.nodes[0].data.navigation_links.map(
                (page, i) => {
                  const isCurrentPage = props.path === page.link.text
                  return (
                    <MotionBox
                      as="li"
                      // whileTap={{ scale: mobNavVisible && 0.95 }}
                      // style={{ marginLeft: 40 }}
                      ml={i === 0 ? 6 : 2}
                      key={i}
                      className="nav-link"
                      // onTap={() => mobTapHandler()}
                      fontSize="sm"
                      onClick={() =>
                        !mobNavVisible &&
                        burgerActive &&
                        clickAllowed &&
                        mobTapHandler(true, "link")
                      }
                      style={{
                        pointerEvents:
                          !mobNavVisible && burgerActive ? "auto" : "none",
                      }}
                      position="relative"
                    >
                      <Link
                        to={page.link.text}
                        key={page.link.text}
                        style={{
                          pointerEvents:
                            mobNavVisible && !isCurrentPage && clickAllowed
                              ? "auto"
                              : "none",
                        }}
                      >
                        <motion.div
                          style={{
                            padding: "8px 12px 7px 12px",
                          }}
                          initial={{ padding: "8px 12px 7px 12px", opacity: 0 }}
                          animate={{
                            color: isCurrentPage
                              ? "rgb(255,255,255)"
                              : "rgb(0,0,0)",
                            paddingRight: !mobNavVisible && isCurrentPage ? 36 + 10 : 10,
                            opacity: !mobNavVisible && !isCurrentPage ? 0 : 1,
                          }}
                          transition={{
                            ease: "easeInOut",
                            duration: ready && mobNavVisible !== null ? 0.4 : 0,
                          }}
                        >
                          {page.label.text}
                        </motion.div>
                        {isCurrentPage && (
                          <motion.div
                            ref={activeItemRef}
                            layout
                            layoutId="mob-highlight"
                            className="nav-highlight"
                            transition={{
                              ease: "easeInOut",
                              duration:
                                ready && mobNavVisible !== null ? 0.3 : 0,
                            }}
                            left={mobLeftOffset}
                            style={{
                              backgroundColor: "black",
                              borderRadius: 20,
                              zIndex: -1,
                              width: "100%",
                            }}
                          />
                        )}
                      </Link>
                    </MotionBox>
                  )
                }
              )}
            </ul>
          </MotionBox>
        </Box>
      </MotionFlex>
    </>
  )
}

export default Nav
